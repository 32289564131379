import { useWidgetSettings } from "../contexts/WidgetSettingsContext";
import WidgetAnalyticsService, { EventTypes } from "../services/analytics";
import uuid from "react-uuid";

export function useAnalytics() {
    
    const {widgetId, widgetSettings} = useWidgetSettings();
    
    function send_analytics(eventType: EventTypes, channelId?: string) {
        if (!widgetId || !widgetSettings) {
            return;
        }

        let userId = window.sessionStorage.getItem("sessionUserId");

        if (!userId) {
        userId = uuid();
        window.sessionStorage.setItem("sessionUserId", userId);
        }
    
        WidgetAnalyticsService.post(widgetSettings?.locationSettings.find(location => location.widgetId === widgetId)!.merchantPublicId, userId, eventType, channelId);
    
    }

    return send_analytics
};